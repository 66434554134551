import { inject, Injectable } from '@angular/core'
import { NavigationEnd, NavigationExtras, Router } from '@angular/router'
import { Location } from '@angular/common'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly router = inject(Router)
  private readonly location = inject(Location)

  private readonly history: string[] = []

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  public back({ defaultNavigate = ['/'], defaultNavigateExtras = {} }: NavigationBackParams = {}) {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigate(defaultNavigate, defaultNavigateExtras)
    }
  }
}

export interface NavigationBackParams {
  defaultNavigate?: (string | number)[]
  defaultNavigateExtras?: NavigationExtras
}
